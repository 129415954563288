import {
  SHOW_UPCOMING_ARTICLES,
  ADD_UPCOMING_ARTICLES,
  DELETE_UPCOMING_ARTICLES
} from "actions";
const initialState = [];
export const upcomingArticlesReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_UPCOMING_ARTICLES:
      return [...state, action.article];
    case SHOW_UPCOMING_ARTICLES:
      return state;
    case DELETE_UPCOMING_ARTICLES:
      return state.filter(article => action.id !== article.id);
    default:
      return state;
  }
};
