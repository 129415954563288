import { combineReducers } from "redux";
import { loadingReducer } from "reducers/loadingReducer";
import { todaysArticlesReducer } from "reducers/todaysArticlesReducer";
import { upcomingArticlesReducer } from "reducers/upcomingArticlesReducer";
import { archiveArticlesReducer } from "reducers/archiveArticlesReducer";
import { addArticleReducer } from "reducers/addArticleReducer";
import { authReducer } from "reducers/authReducer";
const reducers = combineReducers({
  loading: loadingReducer,
  today: todaysArticlesReducer,
  upcoming: upcomingArticlesReducer,
  archive: archiveArticlesReducer,
  add: addArticleReducer,
  user: authReducer
});
export default reducers;
