import {
  LOADING_APP,
  UPLOADING,
  SHOW_TODAYS_ARTICLES,
  ADD_TODAYS_ARTICLES,
  DELETE_TODAYS_ARTICLES,
  SHOW_UPCOMING_ARTICLES,
  ADD_UPCOMING_ARTICLES,
  DELETE_UPCOMING_ARTICLES,
  SHOW_ARCHIVE_ARTICLES,
  ADD_ARCHIVE_ARTICLES,
  DELETE_ARCHIVE_ARTICLES,
  SHOW_ARTICLE,
  ADD_ARTICLE,
  DELETE_ARTICLE
} from "actions";

export const loadingApp = () => {
  return {
    type: LOADING_APP
  };
};

export const uploading = data => {
  return {
    type: UPLOADING,
    data
  };
};

export const showTodaysArticles = () => {
  return {
    type: SHOW_TODAYS_ARTICLES
  };
};

export const addTodaysArticles = article => {
  return {
    type: ADD_TODAYS_ARTICLES,
    article
  };
};

export const deleteTodaysArticles = id => {
  return {
    type: DELETE_TODAYS_ARTICLES,
    id
  };
};

export const showUpcomingArticles = () => {
  return {
    type: SHOW_UPCOMING_ARTICLES
  };
};

export const addUpcomingArticles = article => {
  return {
    type: ADD_UPCOMING_ARTICLES,
    article
  };
};

export const deleteUpcomingArticles = id => {
  return {
    type: DELETE_UPCOMING_ARTICLES,
    id
  };
};

export const showArchiveArticles = () => {
  return {
    type: SHOW_ARCHIVE_ARTICLES
  };
};

export const addArchiveArticles = article => {
  return {
    type: ADD_ARCHIVE_ARTICLES,
    article
  };
};

export const deleteArchiveArticles = id => {
  return {
    type: DELETE_ARCHIVE_ARTICLES,
    id
  };
};

export const showArticle = () => {
  return {
    type: SHOW_ARTICLE
  };
};

export const addArticle = article => {
  return {
    type: ADD_ARTICLE,
    article
  };
};

export const deleteArticle = id => {
  return {
    type: DELETE_ARTICLE,
    id
  };
};
