export const LOADING_APP = `LOADING_APP`;
export const UPLOADING = `UPLOADING`;
export const SHOW_TODAYS_ARTICLES = `SHOW_TODAYS_ARTICLES`;
export const ADD_TODAYS_ARTICLES = `ADD_TODAYS_ARTICLES`;
export const DELETE_TODAYS_ARTICLES = `DELETE_TODAYS_ARTICLES`;
export const SHOW_UPCOMING_ARTICLES = `SHOW_UPCOMING_ARTICLES`;
export const ADD_UPCOMING_ARTICLES = `ADD_UPCOMING_ARTICLES`;
export const DELETE_UPCOMING_ARTICLES = `DELETE_UPCOMING_ARTICLES`;
export const SHOW_ARCHIVE_ARTICLES = `SHOW_ARCHIVE_ARTICLES`;
export const ADD_ARCHIVE_ARTICLES = `ADD_ARCHIVE_ARTICLES`;
export const DELETE_ARCHIVE_ARTICLES = `DELETE_ARCHIVE_ARTICLES`;
export const ADD_ARTICLE = `ADD_ARTICLE`;
export const SHOW_ARTICLE = `SHOW_ARTICLE`;
export const DELETE_ARTICLE = `DELETE_ARTICLE`;
