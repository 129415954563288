import { showUpcomingArticles } from "actions";
import Upcoming from "components/upcoming";
import { connect } from "react-redux";

/* mapStateToProps must always return an object */
const mapStateToProps = state => {
  const { upcoming } = state;
  return { upcoming };
};

const mapDispatchToProps = dispatch => {
  return {
    showUpcomingArticles,
    dispatch
  };
};

const UpcomingContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Upcoming);

export default UpcomingContainer;
