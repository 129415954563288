/** @jsx jsx */
import { jsx } from "theme-ui";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import TodayContainer from "containers/today";
import ArchiveContainer from "containers/archive";
import UpcomingContainer from "containers/upcoming";
import Login from "components/login";
import SideNav from "components/sidenav";
import Header from "components/header";
const PlayGround = () => {
  return (
    <Router>
      <div
        sx={{
          display: "grid",
          gridGap: 0,
          gridTemplateColumns: ["auto", "225px 1fr"],
          height: "calc( 100vh )",
          m: "auto"
        }}
      >
        <aside sx={{ borderRight: "1px solid grey" }}>
          <SideNav />
        </aside>
        <Switch>
          <Route exact path="/">
            <main sx={{ mt: "65px" }}>
              <TodayContainer />
            </main>
          </Route>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/upcoming">
            <main sx={{ mt: "65px" }}>
              <UpcomingContainer />
            </main>
          </Route>
          <Route path="/archive">
            <main sx={{ mt: "65px" }}>
              <ArchiveContainer />
            </main>
          </Route>
        </Switch>
      </div>
    </Router>
  );
};

export default PlayGround;
