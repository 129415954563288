/** @jsx jsx */
import { jsx, Styled } from "theme-ui";
import React from "react";
import { ReactComponent as Logo } from "assets/images/the5ive.svg";
const Login = () => {
  return (
    <div sx={{ height: "100vh", bg: "background" }}>
      <div
        sx={{
          py: "6",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <Logo />
      </div>
    </div>
  );
};

export default Login;
