import { showArchiveArticles } from "actions";
import Archive from "components/archive";
import { connect } from "react-redux";

/* mapStateToProps must always return an object */
const mapStateToProps = state => {
  const { archive } = state;
  return { archive };
};

const mapDispatchToProps = dispatch => {
  return {
    showArchiveArticles,
    dispatch
  };
};

const ArchiveContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Archive);

export default ArchiveContainer;
