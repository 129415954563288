import { ADD_ARTICLE, SHOW_ARTICLE, DELETE_ARTICLE } from "actions";
import uuidv4 from "uuid/v4";
export const addArticleReducer = (state = [], action) => {
  switch (action.type) {
    case ADD_ARTICLE:
      return [
        ...state,
        {
          id: uuidv4(),
          title: action.article.title,
          url: action.article.url,
          image: action.article.image,
          description: action.article.description,
          status: "upcoming",
          savingDate: new Date() || null,
          scheduledDate: null,
          action: {
            title: "Done",
            status: null
          }
        }
      ];
    case SHOW_ARTICLE:
      return state;
    case DELETE_ARTICLE:
      return state.filter(article => action.id !== article.id);
    default:
      return state;
  }
};
