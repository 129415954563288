/** @jsx jsx */
import { jsx } from "theme-ui";
import { useState, useEffect } from "react";
import { X } from "react-feather";
import { useTransition, animated } from "react-spring";
import { Button } from "@theme-ui/components";

let id = 0;

function MessageHub({
  config = { tension: 125, friction: 20, precision: 0.1 },
  timeout = 3000,
  children
}) {
  const [refMap] = useState(() => new WeakMap());
  const [cancelMap] = useState(() => new WeakMap());
  const [items, setItems] = useState([]);
  const transitions = useTransition(items, item => item.key, {
    from: { opacity: 0, height: 0, life: "100%" },
    enter: item => async next =>
      await next({ opacity: 1, height: refMap.get(item).offsetHeight }),
    leave: item => async (next, cancel) => {
      cancelMap.set(item, cancel);
      await next({ life: "0%" });
      await next({ opacity: 0 });
      await next({ height: 0 });
    },
    onRest: item => setItems(state => state.filter(i => i.key !== item.key)),
    config: (item, state) =>
      state === "leave" ? [{ duration: timeout }, config, config] : config
  });

  useEffect(
    () =>
      void children(msg => setItems(state => [...state, { key: id++, msg }])),
    []
  );
  return (
    <div sx={{ position: "fixed", bottom: 60, right: 30 }}>
      {transitions.map(({ key, item, props: { life, ...style } }) => (
        <div
          key={key}
          style={style}
          sx={{
            color: "white",
            backgroundColor: "red",
            borderRadius: "4px"
          }}
        >
          <div
            ref={ref => ref && refMap.set(item, ref)}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              m: "3"
            }}
          >
            <animated.div style={{ right: life }} />
            <p>{item.msg}</p>
            <Button
              onClick={e => {
                e.stopPropagation();
                cancelMap.has(item) && cancelMap.get(item)();
              }}
              sx={{
                color: "white",
                background: "red",
                "&:focus": {
                  outline: "none"
                }
              }}
            >
              <X size={18} />
            </Button>
          </div>
        </div>
      ))}
    </div>
  );
}

export default MessageHub;
