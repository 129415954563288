/** @jsx jsx */
import { jsx, Styled } from "theme-ui";
import { Card, Image } from "@theme-ui/components";
const Article = ({ article }) => {
  if (article === null) {
    return "";
  } else {
    return (
      <Card
        sx={{
          maxWidth: "312px",
          display: "inline-block",
          width: "100%",
          mx: 3,
          mb: 3
        }}
      >
        <a
          href={article.url}
          target="_blank"
          rel="noopener noreferrer"
          sx={{ textDecoration: "none" }}
        >
          <div
            key={article.id}
            sx={{
              background: "transparent",
              my: "2",
              borderRadius: "4px",
              textAlign: "left",
              border: "none",
              boxShadow: "0 0 2px #C0C0C0",
              "&:hover": {
                boxShadow: "0 0 4px #C0C0C0",
                border: "none"
              }
            }}
          >
            <Image
              src={article.image}
              sx={{
                height: "160px",
                width: "100%",
                borderTopRightRadius: "4px",
                borderTopLeftRadius: "4px"
              }}
            />
            <Styled.div sx={{ py: "3", maxHeight: "180px", height: "180px" }}>
              <Styled.h3 sx={{ px: 3 }}>{article.title}</Styled.h3>
              <Styled.p sx={{ px: 3 }}>
                {`${article.description &&
                  article.description.substring(0, 100)}...`}
              </Styled.p>
            </Styled.div>
          </div>
        </a>
      </Card>
    );
  }
};

export default Article;
