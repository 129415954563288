/** @jsx jsx */
import { jsx, ThemeProvider } from "theme-ui";
import { Provider } from "react-redux";
import store from "./store";
import PlayGround from "./routers/playground";
import theme from "./theme";
import { saveState } from "utils/localstorage";

store.subscribe(() => {
  saveState(store.getState());
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <PlayGround />
      </Provider>
    </ThemeProvider>
  );
}

export default App;
