/** @jsx jsx */
import { jsx, Styled } from "theme-ui";
import { NavLink } from "react-router-dom";
import { Clock } from "react-feather";
import uuidv4 from "uuid/v4";
const topNavLinks = [
  {
    id: uuidv4(),
    url: "/",
    text: "Today",
    fi: Clock
  },
  {
    id: uuidv4(),
    url: "/upcoming",
    text: "Scheduled",
    fi: Clock
  },
  {
    id: uuidv4(),
    url: "/collections",
    text: "MyCollections",
    fi: Clock
  },
  {
    id: uuidv4(),
    url: "/archive",
    text: "Archives",
    fi: Clock
  }
];

const bottomNavlinks = [
  {
    id: uuidv4(),
    url: "/notification",
    text: "Notification",
    fi: Clock
  },
  {
    id: uuidv4(),
    url: "/setting",
    text: "Setting",
    fi: Clock
  },
  {
    id: uuidv4(),
    url: "/user",
    text: "User",
    fi: Clock
  }
];

export const NavLinkComponent = ({ id, url, text, fi }) => {
  const Fi = fi;
  console.log(Fi);
  return (
    <Styled.li sx={{ width: "100%", my: "0px", py: "0px" }} key={id}>
      <NavLink
        to={url}
        exact={true}
        activeStyle={{
          backgroundColor: `#045E72`
        }}
        style={{
          textDecoration: "none",
          backgroundColor: "#0c7c94",
          padding: "10px 20px 10px 40px",
          color: `white`,
          width: "100%",
          textAlign: `left`,
          display: `inline-flex`,
          alignItems: "center",
          fontSize: "13px"
        }}
      >
        <div sx={{ display: `inline-flex`, alignItems: `center` }}>
          <Fi size="14" />
          <span style={{ paddingLeft: "8px" }}>{text}</span>
        </div>
      </NavLink>
    </Styled.li>
  );
};

const SideNav = () => (
  <div
    sx={{
      maxWidth: "225px",
      width: "100%",
      position: "fixed",
      backgroundColor: "#0C7C94",
      height: "100vh"
    }}
  >
    <Styled.div sx={{ padding: "10px 20px 10px 40px" }}>
      <Styled.h2 sx={{ fontSize: 4, fontWeight: "400", color: "white" }}>
        The5ive
      </Styled.h2>
    </Styled.div>
    <Styled.ul
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        position: "absolute",
        top: "60px",
        width: "100%",
        py: "0px",
        my: "0px"
      }}
    >
      {topNavLinks.map(navlink => {
        return (
          <NavLinkComponent
            id={navlink.id}
            url={navlink.url}
            text={navlink.text}
            fi={navlink.fi}
          />
        );
      })}
    </Styled.ul>
    <Styled.ul
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        position: "absolute",
        bottom: "0px",
        width: "100%",
        py: "0px",
        my: "0px"
      }}
    >
      {bottomNavlinks.map(navlink => {
        return (
          <NavLinkComponent
            id={navlink.id}
            url={navlink.url}
            text={navlink.text}
            fi={navlink.fi}
          />
        );
      })}
    </Styled.ul>
  </div>
);

export default SideNav;
