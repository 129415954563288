/** @jsx jsx */
import { jsx, Styled } from "theme-ui";
import { Layout } from "components/common";
import Article from "components/article";
import { Helmet } from "react-helmet";
const Upcoming = ({ upcoming }) => {
  return (
    <Layout sx={{ py: "4" }}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>the5ive: the list of upcoming articles to read</title>
        <link rel="canonical" href="https://the5ive.now.sh/upcoming" />
      </Helmet>
      <Styled.h2 sx={{ px: "3" }}>Upcoming Lists:</Styled.h2>
      <div
        sx={{
          display: "grid",
          gridGap: 4,
          gridTemplateColumns: ["auto", "1fr 1fr 1fr"]
        }}
      >
        {upcoming.map(article => {
          return <Article key={article.id} article={article} />;
        })}
      </div>
    </Layout>
  );
};

export default Upcoming;
