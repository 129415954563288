import { showTodaysArticles } from "actions";
import Today from "components/today";
import { connect } from "react-redux";

/* mapStateToProps must always return an object */
const mapStateToProps = state => {
  const { today } = state;
  return { today };
};

const mapDispatchToProps = dispatch => {
  return {
    showTodaysArticles,
    dispatch
  };
};

const TodayContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Today);

export default TodayContainer;
