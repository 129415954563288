import { SHOW_ARCHIVE_ARTICLES } from "actions";
import uuidv4 from "uuid/v4";
const initialState = [
  {
    id: uuidv4(),
    title: "Third Article Title",
    url: "https://uxworks.org/third-article-title",
    image: "https://i.picsum.photos/id/200/554/300.jpg",
    description: "A sample pics",
    status: "archive"
  },
  {
    id: uuidv4(),
    title: "Todays Article Title",
    url: "https://uxworks.org/third-article-title",
    image: "https://i.picsum.photos/id/200/554/300.jpg",
    description: "A sample pics",
    status: "archive"
  },
  {
    id: uuidv4(),
    title: "Third Article Title",
    url: "https://uxworks.org/third-article-title",
    image: "https://i.picsum.photos/id/200/554/300.jpg",
    description: "A sample pics",
    status: "archive"
  },
  {
    id: uuidv4(),
    title: "Todays Article Title",
    url: "https://uxworks.org/third-article-title",
    image: "https://i.picsum.photos/id/200/554/300.jpg",
    description: "A sample pics",
    status: "archive"
  },
  {
    id: uuidv4(),
    title: "Third Article Title",
    url: "https://uxworks.org/third-article-title",
    image: "https://i.picsum.photos/id/200/554/300.jpg",
    description: "A sample pics",
    status: "archive"
  },
  {
    id: uuidv4(),
    title: "Todays Article Title",
    url: "https://uxworks.org/third-article-title",
    image: "https://i.picsum.photos/id/200/554/300.jpg",
    description: "A sample pics",
    status: "archive"
  },
  {
    id: uuidv4(),
    title: "Third Article Title",
    url: "https://uxworks.org/third-article-title",
    image: "https://i.picsum.photos/id/200/554/300.jpg",
    description: "A sample pics",
    status: "archive"
  },
  {
    id: uuidv4(),
    title: "Todays Article Title",
    url: "https://uxworks.org/third-article-title",
    image: "https://i.picsum.photos/id/200/554/300.jpg",
    description: "A sample pics",
    status: "archive"
  },
  {
    id: uuidv4(),
    title: "Third Article Title",
    url: "https://uxworks.org/third-article-title",
    image: "https://i.picsum.photos/id/200/554/300.jpg",
    description: "A sample pics",
    status: "archive"
  },
  {
    id: uuidv4(),
    title: "Todays Article Title",
    url: "https://uxworks.org/third-article-title",
    image: "https://i.picsum.photos/id/200/554/300.jpg",
    description: "A sample pics",
    status: "archive"
  }
];
export const archiveArticlesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_ARCHIVE_ARTICLES:
      return state;
    default:
      return state;
  }
};
