/** @jsx jsx */
import { jsx, Styled } from "theme-ui";
import { useState, useEffect, useRef } from "react";
import { Input, Box, Button } from "@theme-ui/components";
import { Layout } from "components/common";
import { Plus } from "react-feather";
import { useHistory } from "react-router-dom";
import MessageHub from "utils/alert";
const Add = ({
  dispatch,
  addArticle,
  addUpcomingArticles,
  deleteArticle,
  showArticle,
  add,
  upcoming
}) => {
  const ref = useRef(null);
  const history = useHistory();
  const [url, setUrl] = useState("");
  const [finalUrl, setFinalUrl] = useState("");
  const handleOnSubmit = e => {
    e.preventDefault();
    setFinalUrl(url);
    setUrl("");
  };
  const handleOnChange = e => {
    setUrl(e.target.value);
  };

  const handleOnErrorInFetch = () => {
    ref.current("Wrong Address, Try with right address");
  };

  const handleOnFinalUrl = () => {
    if (finalUrl.length !== 0) {
      const response = async URL => {
        try {
          const res = await fetch(`https://the5ive-server.now.sh/?url=${URL}`);
          const data = await res.json();
          if (typeof data === undefined || data === undefined) {
            console.log("Error in reading data");
          }
          const { title, url, image, description } = data;

          const article = {
            title: title,
            url: url,
            image: image,
            description: description
          };
          dispatch(addArticle(article));
        } catch (err) {
          handleOnErrorInFetch();
        }
      };
      response(finalUrl);
    }
  };

  useEffect(() => {
    dispatch(showArticle());
    if (JSON.stringify(add) !== JSON.stringify([])) {
      dispatch(addUpcomingArticles(add[0]));
      dispatch(deleteArticle(add[0].id));
      redirectToUpcoming();
    }
  }, [add]);

  useEffect(() => {
    handleOnFinalUrl();
  }, [finalUrl]);

  const redirectToUpcoming = () => {
    history.push("/upcoming");
  };
  return (
    <Layout
      styling={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <Styled.div sx={{ mt: "12px", width: "100%" }}>
        <Box as="form" onSubmit={handleOnSubmit} sx={{ display: "flex" }}>
          <Input
            name="username"
            sx={{ mr: "4", background: "transparent", fontSize: "2" }}
            onChange={handleOnChange}
            value={url}
            placeholder="Add an article URL https://... to read the article in future!"
          />
          <Button sx={{ px: "10px" }}>
            <Plus />
          </Button>
        </Box>
      </Styled.div>

      <MessageHub children={add => (ref.current = add)} />
    </Layout>
  );
};

export default Add;
