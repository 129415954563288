import {
  addArticle,
  addUpcomingArticles,
  deleteArticle,
  showArticle
} from "actions";
import Add from "components/add";
import { connect } from "react-redux";

/* mapStateToProps must always return an object */
const mapStateToProps = state => {
  const { upcoming, add } = state;
  return { upcoming, add };
};

const mapDispatchToProps = dispatch => {
  return {
    addArticle,
    addUpcomingArticles,
    deleteArticle,
    showArticle,
    dispatch
  };
};

const AddContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Add);

export default AddContainer;
